import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  OnInit,
  ViewChild,
} from "@angular/core";
import { Constant } from "./common/constant";
import { TranslateService } from "@ngx-translate/core";
import {
  NavigationEnd,
  NavigationStart,
  Router,
  ActivatedRoute,
} from "@angular/router";
import { DialogService } from "./common/service/dialog.service";
import { environment } from "../environments/environment";
import {
  Dispatcher,
  GetInfoUser,
  AppQueries,
  GetAllResource,
} from "./state";
import { AppSettings } from "src/app/app.settings";
import { Settings } from "src/app/app.settings.model";
import { LocalStorageService, SessionStorageService } from "ngx-webstorage";
import { Subscription } from "rxjs";
import { EventManagerService } from "./common/service/event-manager.service";
import { NgbModal, NgbModalOptions } from "@ng-bootstrap/ng-bootstrap";
import { NewOTPComponent } from "src/app/common/components/otp/otp.component";
import { UserProfile } from "./common/models/users.model";
import { Observable } from "rxjs";
import { AccountService } from "./common/service/account.service";
import { MaintainceService } from "./common/service/maintaince.service";
/**
 * declare Jquery
 * */
declare let $: any;
declare let ga: any;
declare var require: any;
@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
  providers: [AppSettings],
})
export class AppComponent implements OnInit, AfterViewInit {
  public Constant = Constant;
  public firstLoad: boolean = true;
  public isShowLoading: boolean = true;
  public settings: Settings;
  public eventSubscribers?: Subscription[] = [];
  ignoreApi: any[] = [];
  otp: any;
  product: any;
  userProfile: UserProfile;
  online$: Observable<boolean>;
  isOffline: boolean = false;
  minHeight: string = "1500px";
  tokenRobo: any;
  constructor(
    private translate: TranslateService,
    private router: Router,
    private dialog: DialogService,
    private dispatcher: Dispatcher,
    private appSettings: AppSettings,
    private eventManager: EventManagerService,
    private $localStorage: LocalStorageService,
    private $sessionStorage: SessionStorageService,
    private modalService: NgbModal,
    private appQueries: AppQueries,
    private accountService: AccountService,
    private maintainceService: MaintainceService,
    public cdf: ChangeDetectorRef,
    public route: ActivatedRoute
  ) {
    this.settings = this.appSettings.settings;

    this.route.params.subscribe((params) => {
      if (location.search.substring(0, 14) === "?fb_action_ids") {
        location.search = "";
      }
    });
    this.route.queryParams.subscribe((params) => {
      if (params.utm_source === "accesstrade" && params.aff_sid) {
        this.$localStorage.store("utm_source", params.utm_source);
        this.$localStorage.store("aff_sid", params.aff_sid);
      }
    });
  }

  ngOnInit() {
    $('[data-toggle="tooltip"]').tooltip();
    this.translate.use("vi_VN");
    this.initSingleApis();
    this.initIgnorepis();
    this.initApiNoLoading();
    setTimeout(() => {
      this.firstLoad = false;
    }, 5000);
    this.dispatcher.fire(new GetAllResource());
    setInterval(() => {
      this.dispatcher.fire(new GetAllResource());
    }, 60 * 60 * 1000);
    const token =
      this.$localStorage.retrieve("TOKEN") ||
      this.$sessionStorage.retrieve("TOKEN");
    if (token) {
      this.dispatcher.fire(new GetInfoUser(true));
    }
    let scriptId = "google-analytics";
    this.eventSubscribers = [
      this.appQueries.userProfile$.subscribe((userProfile) => {
        this.userProfile = userProfile;
      }),
      this.eventManager.subscribe("Fmarket.httpError", (res) => {
        console.log("API error: ", res.content);
        const messsage = res.content.error.message || res.content.error.detail;
        if (!this.ignoreError(res.content.url || "")) {
          const text = window.navigator.onLine
            ? "Hiện tại chức năng đang được cập nhật"
            : "Có sự cố về kết nối mạng. Vui lòng kiểm tra lại.";
          this.dialog.showError(messsage || text);
        }
      }),
      this.eventManager.subscribe("Fmarket.maintainHttpError", (res) => {
        console.log("API error: ", res.content);
        const messsage = res.content.error.message || res.content.error.detail;
        if (!this.ignoreError(res.content.url || "")) {
          if (!window.navigator.onLine) {
            this.dialog.showNoConnectInternet();
          } else {
            if (!(this.userProfile && this.userProfile.id)) {
              // this.router.navigate(['/503']);
            } else {
              this.maintainceService.addAPI(res.content.message);
            }
          }
        }
      }),
      this.eventManager.subscribe("Fmarket.error", (res) => {
        const text = window.navigator.onLine
          ? "Hiện tại hệ thống đang gặp cập nhật. Vui lòng quay lại sau !"
          : "Có sự cố về kết nối mạng. Vui lòng kiểm tra lại.";
        this.dialog.showError(res.content.messsage || text);
      }),
      this.eventManager.subscribe("httpError-401", (res) => {
        if (!this.ignore401(res.content.url || "")) {
          this.accountService.logout();
          this.modalService.dismissAll();
        }
      }),
      this.eventManager.subscribe("open-otp", (res) => {
        this.openOTP(res.content);
      }),
      this.eventManager.subscribe("show-spinner", (res) => {
        this.isShowLoading = true;
        this.cdf.detectChanges();
      }),
      this.eventManager.subscribe("close-spinner", (res) => {
        this.isShowLoading = false;
        this.cdf.detectChanges();
      }),
      this.eventManager.subscribe("dismiss-all-modal", (res) => {
        this.modalService.dismissAll();
      }),
    ];
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        sessionStorage.setItem(Constant.key_local_current_screen, event.url);
        if (event.url.includes('isTest=true')) {
          document.documentElement.style.setProperty(`--display-test`, 'block');
        }
        // this.dialog.destroy();
      }
    });
    if (environment.production) {
      this.router.errorHandler = (error) => {
        if (navigator.onLine) {
          location.reload();
        } else {
          this.dialog.showError(error);
        }
      };
    }
    if (document.getElementById(scriptId)) {
      return;
    }

    /* Scroll to active navbar tab */
    $(document).on("click", ".tab-scrollX .nav-link", function (e) {
      let tar = $(e.target).parents("li");
      let tarParent = tar.parents("ul");
      let x = tar.parents("body");
      tarParent.animate({
        scrollLeft:
          tar.offset().left + tarParent.scrollLeft() - x.width() / 1.5,
      });
    });

    /* Scroll to active navbar tab mobile */
    $(document).on("click", ".tab-scrollX-mobile .nav-link", function (e) {
      let tar = $(e.target).parents("li");
      let tarParent = tar.parents("ul");
      let x = tar.parents("body");
      tarParent.animate({
        scrollLeft:
          tar.offset().left + tarParent.scrollLeft() - tarParent.width() / 2,
      });
    });

    // Chatting
    let chatDiv = ".chat-container div";
    $(window).bind("scroll", function (event) {
      $(chatDiv).removeClass("ani");
    });

    $(document).on("click", ".phone", function () {
      $(chatDiv).toggleClass("ani");
    });

    $(document.body).on("hide.bs.modal", function () {
      $(".horizon-mobile").scrollTop(0);
    }); // Reset scroll mobile popup

    $(document.body).on("hidden.bs.modal", function () {
      $("body").css("padding-right", "0");
    });
    $(window).scroll((e) => {
      this.minHeight =
        e.target.body.clientHeight > 1500
          ? e.target.body.clientHeight + "px"
          : "1500px";
    });
  }
  ngAfterViewInit(): void {
    $("body").on("click", (e) => {
      this.eventManager.broadcast({
        name: "body-click",
        content: {
          event: e,
        },
      });
    });
  }
  noticeOffline() {
    if (this.isOffline) {
      return;
    }
    this.isOffline = true;
    this.dialog.showNoConnectInternet();
  }
  openOTP(payload) {
    const otp: any = Object.assign({}, this.otp);
    payload = payload || {};
    const user = this.$localStorage.retrieve("USER_PROFILE") || {};
    const options: NgbModalOptions = {
      backdrop: "static",
      keyboard: false,
    };
    options.centered = true;
    options.windowClass = "otp-order-label";
    const modalRef = this.modalService.open(NewOTPComponent, options);
    const properties = payload.isReopen ? otp : payload;
    modalRef.componentInstance.phone = payload.phone || user.phone;
    Object.keys(properties || {}).forEach((key) => {
      modalRef.componentInstance[key] = properties[key] || {};
    });
    if (!payload.isReopen) {
      Object.keys(properties || {}).forEach((key) => {
        otp[key] = properties[key] || {};
      });
      otp.startTime = Date.now();
      otp.failTimes = 0;
      modalRef.componentInstance.startTime = otp.startTime;
    }
    this.otp = otp;
  }
  initSingleApis() {
    const singleAPIs = {};
    singleAPIs[Constant.key_local_current_screen] = 0;
  }
  initIgnorepis() {
    this.ignoreApi = [
      "login-partner",
      "forgot-password",
      "financial-health-check-create-new",
      "financial-health-check/preview-send-customer",
      "financial-health-check/send-customer",
      "financial-health-check/send-history-list",
      "financial-health-check/export",
    ];
  }
  initApiNoLoading() {
    const noLoadingApi = [{ url: "res/products/filter", method: "POST" }];
    sessionStorage.setItem("noLoadingAPIs", JSON.stringify(noLoadingApi));
  }
  ignoreError(url) {
    return this.ignoreApi.some((api) => url.includes(api));
  }
  ignore401(url) {
    const ignoreApis = [
      "sign-up",
      "activate-account",
      "forgot-password",
      "reset-password",
    ];
    return ignoreApis.some((api) => url.includes(api));
  }
}
