import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { LocalStorageService} from 'ngx-webstorage';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpResponse } from '@angular/common/http';
import {environment} from '../../../environments/environment';
import { Constant } from '../constant';
import { concatMap, tap } from 'rxjs/operators';
import { NgbModalOptions, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { EventManagerService } from '../service/event-manager.service';
import { retry, retryWhen, delay } from 'rxjs/operators';
import { throwError } from 'rxjs';
declare let $;
@Injectable({ providedIn: 'root' })
export class ExecuteBeforeRequestInterceptor implements HttpInterceptor {
  private readonly SERVER_API_URL: any;
  extra: any;
  quanlity: any = 0;
  Constant = Constant;
  singleAPIs: any;
  isLoad: boolean = false;
  modalRef: any;
  retryCount = 3;
  listNoLoadingAPI: any = [];
  retryWaitMilliSeconds = 5000;
  constructor(
    private localStorage: LocalStorageService, 
    private modalService: NgbModal,
    private eventManagerService: EventManagerService,
  ) {
    this.SERVER_API_URL = environment.urlBackEnd;
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpResponse<any>> {
    this.singleAPIs = this.singleAPIs ||  JSON.parse(sessionStorage.getItem('singleAPIs')) || {};
    let splits = request.url.split('/');
    let api = '';
    splits.forEach((item, index) => {
        if (index > 2 && !parseInt(item)) {
            api += '/' + item;
        }
    })
    api = api.replace('/web', '');
    this.beforeRequest(api, request.method);
    if (!this.singleAPIs) {
      this.singleAPIs = JSON.parse(sessionStorage.getItem('singleAPIs'));
    }
    if (this.singleAPIs[api] === 0) {
      this.singleAPIs[api]++;
      this.updateCache(api, 1);
    } else if (this.singleAPIs[api] && this.singleAPIs[api] > 0) {
      this.hideSpinner(api);
      return of();
    }
    return next.handle(request)
      .pipe(
        tap(
          (res: HttpResponse<any>) => {
            const version = localStorage.getItem("version");
            this.extra = res.body ? res.body.extras : null;
            if (this.extra) {
              if (version && this.extra.clientVersion &&  this.extra.clientVersion != version) {
                  localStorage.setItem("version", this.extra.clientVersion);
                  location.reload(); 
              } else  if (!version) {
                  localStorage.setItem("version", this.extra.clientVersion);
              }
           }
          },
          (err: any) => {
            this.hideSpinner(api);
            if (this.singleAPIs[api] !== undefined) {
              setTimeout(() => {
                this.updateCache(api, 0);
              }, 3000)
            }
            return err;
          },
          () => {
            this.hideSpinner(api);
            if (this.singleAPIs[api] !== undefined) {
              setTimeout(() => {
                this.updateCache(api, 0);
              }, 3000)
            }
          }
        ),
        retryWhen(error => 
          error.pipe(
            concatMap((error, count) => {
              console.log('retry - api: ', count + '-' + api);
              if (count < this.retryCount && !error.status) {
                this.eventManagerService.broadcast({
                  name: 'show-spinner',
                  content: {}
                })
                return of(error);
              }
              return throwError(error);
            }),
            delay(this.retryWaitMilliSeconds)
          )
        )
      )
  }
  private updateCache(api, value) {
    this.singleAPIs[api] = value;
  }
  private checkExistNoLoadingAPI(api, method?) {
    this.listNoLoadingAPI = (this.listNoLoadingAPI.length  ?  this.listNoLoadingAPI :  JSON.parse(sessionStorage.getItem('noLoadingAPIs'))) || [];
    return this.listNoLoadingAPI.some((item: any) => { 
      return api.includes(item.url) && (!item.method || (item.method && item.method === method));
    })
  }
  private beforeRequest(api, method?): void {
    if (this.checkExistNoLoadingAPI(api, method)) {
      // console.log('no loading: ', api);
      return;
    }
    // console.log('add api: ', api);
    this.quanlity++;
    // show spinner
    setTimeout(() => {
      if (this.isLoad || !this.quanlity) {
        return;
      }
      this.isLoad = true;
      console.log('loading: ', api);
      this.eventManagerService.broadcast({
        name: 'show-spinner',
        content: {}
      })
    }, 2000)
    // show spinner
    // setTimeout(() => {
    //   if (this.isLoad) {
    //     this.hideSpinner(api);
    //   }
    // }, 1000 * 5)
  }
  hideSpinner(api) {
    if (this.quanlity > 0) {
      this.quanlity--;
    }
    // close spinner
    if (this.quanlity == 0) {
      console.log('close: ', api);
      this.isLoad = false;
      this.eventManagerService.broadcast({
        name: 'close-spinner',
        content: {}
      })
    }
  }
}
