import { ModuleWithProviders } from "@angular/core";
import { Routes, RouterModule, PreloadAllModules } from "@angular/router";
import { Constant } from "./common/constant";
import { NotFoundComponent } from "./common/components/notFound/notFound.component";
import { MaintenanceComponent } from "./common/components/maintenance/maintenance.component";
import { PublicViewImageComponent } from "./common/components/public-view-image/public-view-image";

const routes: Routes = [
  {
    path: "",
    redirectTo: "/home",
    pathMatch: "full",
  },
  {
    path: "null",
    redirectTo: "/home",
    pathMatch: "full",
  },
  { path: "view-image", component: PublicViewImageComponent },
  {
    path: "account",
    loadChildren: () =>
      import("./account/account.module").then(
        (m) => m.AccountModule
      ),
  },

  {
    path: "auth",
    loadChildren: () =>
      import("./authorization/auth.module").then((m) => m.AuthModule),
  },
  {
    path: "activation",
    loadChildren: () =>
      import("./authorization/auth.module").then((m) => m.AuthModule),
  },
  {
    path: "",
    loadChildren: () =>
      import("./dashboard/dashboard.module").then((m) => m.DashboardModule),
  },
  { path: "503", component: MaintenanceComponent },
  { path: "**", component: NotFoundComponent },
];

export const AppRouting = RouterModule.forRoot(routes, {
  preloadingStrategy: PreloadAllModules, // <- comment this line for activate lazy load
  // useHash: true
});
